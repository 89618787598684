<script lang="ts" setup>
type Props = {
  containerClass?: string;
  defaultPadding?: boolean;
};

const { containerClass = "", defaultPadding = true } = defineProps<Props>();

const mergedContainerClass = computed(() => {
  if (!defaultPadding) {
    return containerClass;
  }

  return `px-5 lg:px-10 2xl:px-20 px-0 ${containerClass}`;
});
</script>

<template>
  <div class="w-screen flex justify-center">
    <div class="container" :class="mergedContainerClass">
      <slot></slot>
    </div>
  </div>
</template>
